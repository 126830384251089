.svg-error-page {
  background-size: auto 530px;
  background: #bddef0 url("./error-page.svg") no-repeat bottom center;
  overflow: hidden;
  min-height: 500px;
}

.svg-page {
  flex: 1 1 auto;
}
