.coffee-small-container {
  position: relative;
  background: transparent;
  margin-right: 15px;
}

.cup-small {
  position: relative;
  width: 28px;
  height: 30px;
  background: linear-gradient(to right, #f9f9f9, #d9d9d9);
  border-bottom-left-radius: 45%;
  border-bottom-right-radius: 45%;
  box-shadow: 0 3.5px 2.5px rgba(0,0,0,0.7);
}

.top-coffee-small {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(to right, #f9f9f9, #d9d9d9);
  border-radius: 50%;
}

.coffee-circle-small {
  position: absolute;
  top: .5px;
  left: 1px;
  width: calc(100% - 2px);
  height: 5px;
  background: linear-gradient(to left, #f9f9f9, #d9d9d9);
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
}

.coffee-small {
  position: absolute;
  top: 1.5px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#6c3a29, #8b1408);
  border-radius: 50%;
}

.handle-small {
  position: absolute;
  right: -7px;
  top: 4px;
  width: 16px;
  height: 18px;
  border: 2.5px solid #dcdcdc;
  border-left: 2.5px solid transparent;
  border-bottom: 2.5px solid transparent;
  transform: rotate(42deg);
  border-radius: 50%;
}

.vapor-small {
  position: relative;
  display: flex;
  z-index: 1;
  padding: 0 2px;
}

.vapor-small span {
  position: relative;
  bottom: 2px;
  display: block;
  margin: 0 1px 1px;
  min-width: 2px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  opacity: 0;
  filter: blur(1px);
}

.one {
  animation: animate 5s linear infinite;
  animation-delay: calc(1 * -0.5s)
}

.two {
  animation: animate 5s linear infinite;
  animation-delay: calc(2 * -0.5s)
}

.three {
  animation: animate 5s linear infinite;
  animation-delay: calc(3 * -0.5s)
}

.four {
  animation: animate 5s linear infinite;
  animation-delay: calc(4 * -0.5s)
}

.five {
  animation: animate 5s linear infinite;
  animation-delay: calc(5 * -0.5s)
}

.six {
  animation: animate 5s linear infinite;
  animation-delay: calc(6 * -0.5s)
}

.seven {
  animation: animate 5s linear infinite;
  animation-delay: calc(7 * -0.5s)
}

.eight {
  animation: animate 5s linear infinite;
  animation-delay: calc(8 * -0.5s)
}

.nine {
  animation: animate 5s linear infinite;
  animation-delay: calc(9 * -0.5s)
}

.ten {
  animation: animate 5s linear infinite;
  animation-delay: calc(10 * -0.5s)
}

.eleven {
  animation: animate 5s linear infinite;
  animation-delay: calc(11 * -0.5s)
}

.twelve {
  animation: animate 5s linear infinite;
  animation-delay: calc(12 * -0.5s)
}

.thirteen {
  animation: animate 5s linear infinite;
  animation-delay: calc(13 * -0.5s)
}

.fourteen {
  animation: animate 5s linear infinite;
  animation-delay: calc(14 * -0.5s)
}

.fifteen {
  animation: animate 5s linear infinite;
  animation-delay: calc(15 * -0.5s)
}

.sixteen {
  animation: animate 5s linear infinite;
  animation-delay: calc(16 * -0.5s)
}

.seventeen {
  animation: animate 5s linear infinite;
  animation-delay: calc(17 * -0.5s)
}

.eighteen {
  animation: animate 5s linear infinite;
  animation-delay: calc(18 * -0.5s)
}

.nineteen {
  animation: animate 5s linear infinite;
  animation-delay: calc(19 * -0.5s)
}

.twenty {
  animation: animate 5s linear infinite;
  animation-delay: calc(20 * -0.5s)
}

@keyframes animate {
  0% {
    transform: translateY(0) scaleX(1);
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  50% {
    transform: translateY(-15px) scaleX(5);
  }
  95% {
    opacity: 0;
  }
  100% {
    transform: translateY(-30px) scaleX(10);
  }
}
